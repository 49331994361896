@keyframes business-1 {
  from {
    top: 45%;
    opacity: 1;
  }
  45% {
    top: -30%;
    opacity: 1;
  }
  46% {
    top: -35%;
    opacity: 1;
  }
  47% {
    top: -35%;
    opacity: 0;
  }
  49% {
    top: 120%;
    opacity: 0;
  }
  50% {
    top: 110%;
    opacity: 1;
  }
  to {
    top: 45%;
  }
}
@keyframes business-2 {
  from {
    top: 25%;
    opacity: 1;
  }
  // 29% {
  //   top: -30%;
  //   opacity: 1;
  // }
  38% {
    top: -50%;
    opacity: 1;
  }
  39% {
    top: -50%;
    opacity: 0;
  }
  40% {
    top: 110%;
    opacity: 0;
  }
  45% {
    top: 100%;
    opacity: 1;
  }
  to {
    top: 25%;
  }
}
@keyframes business-3 {
  from {
    top: 10%;
    opacity: 1;
  }
  20% {
    top: -35%;
    opacity: 1;
  }
  25% {
    top: -35%;
    opacity: 0;
  }
  30% {
    top: 140%;
    opacity: 0;
  }
  35% {
    top: 100%;
    opacity: 1;
  }
  to {
    top: 10%;
  }
}
@keyframes business-4 {
  from {
    top: 80%;
    opacity: 1;
  }
  // 85% {
  //   top: -40%;
  //   opacity: 1;
  // }
  86% {
    top: -50%;
    opacity: 1;
  }
  87% {
    top: -50%;
    opacity: 0;
  }
  88% {
    top: 120%;
    opacity: 0;
  }
  89% {
    top: 100%;
    opacity: 1;
  }
  to {
    top: 80%;
  }
}
@keyframes business-5 {
  from {
    top: 50%;
    opacity: 1;
  }
  // 50% {
  //   top: -20%;
  //   opacity: 1;
  // }
  51% {
    top: -50%;
    opacity: 1;
  }
  52% {
    top: -50%;
    opacity: 0;
  }
  53% {
    top: 120%;
    opacity: 0;
  }
  54% {
    top: 110%;
    opacity: 1;
  }
  to {
    top: 50%;
  }
}
@keyframes experience {
  from {
    top: 88%;
    opacity: 1;
  }
  88%,
  89% {
    top: -30%;
    opacity: 1;
  }
  90% {
    top: -30%;
    opacity: 0;
  }
  91% {
    top: 120%;
    opacity: 0;
  }
  92% {
    top: 110%;
    opacity: 1;
  }
  to {
    top: 88%;
  }
}
@keyframes conference {
  from {
    top: 8%;
    opacity: 1;
  }
  27%,
  28% {
    top: -30%;
    opacity: 1;
  }
  29% {
    top: -30%;
    opacity: 0;
  }
  30% {
    top: 120%;
    opacity: 0;
  }
  31% {
    top: 110%;
    opacity: 1;
  }
  to {
    top: 8%;
  }
}
@keyframes consulting {
  from {
    top: 35%;
    opacity: 1;
  }
  48%,
  49% {
    top: -30%;
    opacity: 1;
  }
  50% {
    top: -30%;
    opacity: 0;
  }
  51% {
    top: 100%;
    opacity: 0;
  }
  52% {
    top: 110%;
    opacity: 1;
  }
  to {
    top: 35%;
  }
}
@keyframes gym {
  from {
    top: 76%;
    opacity: 1;
  }
  68%,
  69% {
    top: -40%;
    opacity: 1;
  }
  70% {
    top: -40%;
    opacity: 0;
  }
  71% {
    top: 120%;
    opacity: 0;
  }
  72% {
    top: 110%;
    opacity: 1;
  }
  to {
    top: 76%;
  }
}
@keyframes business_two {
  from {
    top: 76%;
    opacity: 1;
  }
  78%,
  79% {
    top: -30%;
    opacity: 1;
  }
  80% {
    top: -30%;
    opacity: 0;
  }
  81% {
    top: 120%;
    opacity: 0;
  }
  82% {
    top: 110%;
    opacity: 1;
  }
  to {
    top: 76%;
  }
}
@keyframes company {
  82%,
  from {
    top: 110%;
    opacity: 1;
  }
  78%,
  79% {
    top: -20%;
    opacity: 1;
  }
  80% {
    top: -20%;
    opacity: 0;
  }
  81% {
    top: 120%;
    opacity: 0;
  }
  to {
    top: 100%;
  }
}
