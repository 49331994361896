@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');
.tran {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
html {
  overflow-x: hidden;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  color: #282828;
  overflow-x: hidden;
}

.container {
  max-width: 1520px;
  @include mw-1280 {
    max-width: 860px;
  }
}
.section-title {
  h3 {
    letter-spacing: 0.0025em;
    font-weight: 700;
    font-size: 40px;
    @include mw-1280 {
      font-size: 28px;
      white-space: nowrap;
    }
    @include mw-768 {
      white-space: normal;
      font-size: 26px;
    }
  }
  span {
    width: 47px;
    height: 6px;
    background-color: #ef5f21;
    display: block;
    margin: 20px 0;
  }
  @include mw-768 {
    text-align: center;
    margin-bottom: 20px;
    span {
      margin: auto;
    }
  }
}
.section-content {
  font-weight: 300;
  font-size: 22px;
  line-height: 35px;
  /* or 159% */
  color: #414141;
  letter-spacing: 0.0015em;
  @include mw-1280 {
    font-size: 20px;
  }
  @include mw-768 {
    text-align: center;
  }

  strong {
    font-weight: 600;
  }
}

.desktop {
  // display: block;
  @include mw-768 {
    display: none;
  }
}
.mobile {
  display: none;
  @include mw-768 {
    display: block;
  }
}
