@import 'mixins';

@import './_share/slick-theme';
@import './_share/slick';
@import './_share/key-frame';

.news-item,
.partner-item img,
.tiktok .slick-center img,
.tran {
  transition: all 0.3s ease 0s;
}

@import 'base';
@import './_share/header';
@import './_share/footer';
@import './screens/customs';
@import './screens/news';
