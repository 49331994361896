.main-content {
  padding-top: 100px;
}
.main-title {
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.0025em;
  margin-bottom: 30px;
  @include mw-1280 {
    font-size: 28px;
  }
  @include mw-768 {
    font-size: 26px;
    margin-bottom: 20px;
  }
}
.detail-news {
  margin-bottom: 20px;
  .img {
    display: block;
    height: 130px;
    overflow: hidden;
    @include mw-1280 {
      height: 100px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  a {
    text-decoration: none;
  }
  h5 {
    font-size: 26px;
    line-height: 33px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: #282828;
    @include mw-1280 {
      font-size: 22px;
    }
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .col-md-4 {
    @include mw-1280 {
      max-width: 50%;
      width: 50%;
    }
  }
  .col-md-8 {
    @include mw-1280 {
      max-width: 50%;
      width: 50%;
    }
  }
  .time {
    color: #8e8e8e;
  }
  &:hover {
    h5 {
      color: $main-color;
    }
  }
  &-left {
    .img {
      height: 420px;
      @include mw-1280 {
        height: 230px;
      }
    }
  }
}
.mobile-news {
  .img {
    height: 200px;
  }
}
.news-list {
  .detail-news {
    margin: 20px 0;
    .img {
      height: 200px;
      @include mw-1280 {
        height: 150px;
      }
      @include mw-768 {
        height: 100px;
      }
    }
    h5 {
      @include mw-768 {
        font-size: 18px;
      }
    }
    .des {
      @include mw-768 {
        display: none;
      }
    }
    .col-md-3 {
      @include mw-1280 {
        max-width: 33.33%;
        width: 33.33%;
      }
    }
    .col-md-9 {
      @include mw-1280 {
        max-width: 66.66%;
        width: 66.66%;
      }
    }
    .col-md-3,
    .col-md-9 {
      @include mw-768 {
        max-width: 50%;
        width: 50%;
      }
    }
  }
}
.contact {
  padding: 100px 0;
  min-height: calc(100vh - 320px);
  &-title {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.0025em;
    margin-bottom: 30px;
    font-weight: 700;
  }
  h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: flex-start;
      font-size: 17px;
      line-height: 28px;
      margin-bottom: 16px;
      img {
        margin-top: 5px;
        margin-right: 10px;
      }
      a {
        color: #282828;
        text-decoration: none;
        margin-left: 5px;
      }
    }
  }
  &-map {
    img {
      width: 100%;
    }
  }
}
.detail {
  &-section {
    padding-top: 100px;
  }
  &-title {
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.0025em;
    @include mw-1280 {
      font-size: 28px;
    }
    @include mw-768 {
      font-size: 26px;
    }
  }
  &-time {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    span {
      display: flex;
      align-items: center;
      margin: 20px 32px 20px 0;
      color: #8b8d8f;
      img {
        margin-right: 5px;
        width: 25px;
      }
      @include mw-768 {
        margin: 10px 0;
      }
    }
  }
  &-content {
    text-align: justify;
    p {
      max-height: 400px;
      overflow: hidden;
      span {
        margin: 0 auto;
        display: block;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    img {
      max-height: 400px;
      margin: 10px auto;
    }
  }
  &-share {
    display: flex;
    align-items: center;
    margin: 20px 0;
    img {
      margin-left: 5px;
    }
  }
}
.related {
  padding: 30px 0;
  &-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 24px;
  }
  .col-md-3 {
    @include mw-1280 {
      width: 50%;
    }
    @include mw-768 {
      width: 100%;
    }
  }
}
.recruit {
  min-height: calc(100vh - 300px);
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: $main-color;
  }
  table {
    width: 75%;
    @include mw-1280 {
      width: 100%;
    }
    thead {
      tr {
        th {
          box-shadow: none;
          padding: 18px 15px;
          font-size: 17px;
          font-weight: 600;
          white-space: nowrap;
        }
      }
    }
    tbody {
      tr {
        .bold {
          font-weight: 600;
          font-size: 22px;
          @include mw-768 {
            font-size: 20px;
          }
          a {
            color: #282828;
            text-decoration: none;
            &:hover {
              color: $main-color;
            }
          }
          p {
            font-weight: 400;
          }
        }
        td {
          padding: 18px 15px;
          font-size: 17px;
          vertical-align: initial;
        }
      }
    }
  }
}
.pagination {
  & .page-item {
    margin: 0 4px;

    & .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      height: 38px;
      width: 38px;
      background-color: #fff;
      border: solid 1px #e5e6ec;
      border-radius: 4px;
      color: #313131;
      outline: none;
      box-shadow: none;

      &:hover {
        background-color: $main-color;
        border-color: $main-color;
        color: #fff;
      }
    }

    &.active {
      & .page-link {
        background-color: $main-color;
        border-color: $main-color;
        color: #fff;
      }
    }
    &.previous,
    &.next {
      & .page-link {
        font-size: 24px;
        background-color: #fff;
        border-color: #f8e6de;
        color: $main-color;

        &:hover {
          background-color: #fff;
          border-color: $main-color;
          color: $main-color;
        }
      }

      &.disabled .page-link {
        background-color: #e5e6ec80;
        border-color: #e5e6ec;
        color: #b5b5c3a8;
      }
    }
  }
}
.mobile-news {
  .slick-dots {
    top: 160px;
    bottom: auto;
  }
}
