header {
  height: 72px;
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  font-size: 16px;
  z-index: 10;

  &::before {
    background-color: #191919;
    bottom: -1px;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 0.25s linear;
    transition: opacity 0.25s linear;
    z-index: -1;
  }
  @include mw-768 {
  }
  .header-inner {
    width: 100%;
    background: rgba(255, 255, 255, 1) !important;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;

      @include mw-768 {
      }
    }
  }
  .container {
    max-width: 1800px;
    padding: 0;
  }
  .logo {
    cursor: pointer;
    // width: 124px;
  }
  .list-page {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    list-style: none;
    a {
      font-weight: 600;
      line-height: 17px;
      color: #000;
      font-size: 17px;
      align-self: center;
      display: block;
      text-decoration: none;
      padding: 10px;
      margin-left: 20px;
    }
    .launch {
      background: #ef5f21;
      border-radius: 4px;
      display: block;
      color: #fff;
      text-decoration: none;
      padding: 6px 23px;
      width: 100px;
      font-size: 15px;
      text-align: center;
      text-transform: uppercase;
      @include mw-768 {
        margin-top: 10px;
      }
    }
    .activePage {
      a {
        color: $main-color;
      }
    }
    @include mw-768 {
      display: block;
      height: 0;
      position: absolute;
      width: 100vw;
      justify-content: center;
      left: 0;
      top: 72px;
      transition: all 0.3s ease 0s;
      flex-wrap: wrap;
      overflow: hidden;
      background: rgba(255, 255, 255, 1);
      padding-left: 0;
    }
  }
}
.active {
  &::before {
    @include mw-768 {
      opacity: 0.5;
      content: '';
      transition: opacity 0.25s linear;
    }
  }
}
.header-bg {
  background: rgba(255, 255, 255, 0.7);
}
.bg-menu {
  margin-right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  display: none;
  @include mw-768 {
    display: block;
  }
}

.bg-menu.active {
  background: rgba(255, 255, 255, 0.25);
}

.img-menu {
  width: 24px;
  height: 16px;
}

.hd-right {
  color: #000;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  cursor: pointer;
}

.lg-choose {
  padding-left: 24px;
  display: flex;
  width: 42px;
  margin-bottom: 3px;
  white-space: nowrap;
  min-width: 150px;
  align-items: center;
  justify-content: flex-end;
  @include mw-768 {
    min-width: 90px;
  }
  .option {
    display: flex;
    align-items: center;
    .text {
      display: block;
      padding: 3px;
      @include mw-768 {
        display: none;
      }
    }
  }
}

.language {
  position: relative;
}

.list-lang {
  background: #fff;
  // color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  top: 25px;
  height: 0;
  cursor: pointer;
  transition: ease-in 0.2s;
  overflow: hidden;
  position: absolute;
  padding-left: 0;
  min-width: 150px;
  right: 0;
  @include mw-768 {
    // min-width: 150px;
  }
}

.list-lang.active {
  border: solid 1px #e5e5e5;
  height: 80px;
  // transition: ease-in 0.2s;
  @include mw-768 {
    // transition: all 0.3s ease 0s;
  }
}

.list-lang.active li:hover {
  background-color: #5f8ff5;
}

.list-lang li {
  padding: 10px 20px;
  width: 100%;
  list-style: none;
  img {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 991px) {
  header .list-page.active li.activePage a {
    color: $main-color;
  }

  header .list-page.active {
    border-top: 1px solid #ececec;
  }
  header .list-page.active {
    height: 207px;
    transition: all 0.3s ease 0s;
  }

  header .list-page li.activePage img {
    display: none;
  }
  header .list-page li:not(:first-child) {
    margin-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  /* header */
  header .list-page.active {
    height: 210px;
  }
  header .list-page {
    justify-content: start;
  }
}
