.footer {
  background-color: #000614;
  padding: 50px 0 20px;
  h3 {
    color: #b9b9b9;
    font-size: 20px;
    margin: 20px 0;
  }
  &-contact {
    max-width: 600px;
    display: flex;
    align-items: flex-start;
    color: #b9b9b9;
    img {
      margin-top: 3px;
      margin-right: 10px;
    }
    a {
      color: #b9b9b9;
      text-decoration: none;
      margin-left: 5px;
    }
    @include mw-768 {
      margin-bottom: 10px;
    }
  }
  .bottom {
    margin: 20px 0;
    display: flex;
    align-items: center;
    color: #b9b9b9;
    font-size: 14px;
    img {
      margin-right: 5px;
    }
  }
  .col-md-9 {
    @include mw-1280 {
      width: 66.667%;
    }
    @include mw-768 {
      width: 100%;
    }
  }
  .col-md-3 {
    @include mw-1280 {
      width: 33.33%;
    }
    @include mw-768 {
      width: 100%;
    }
  }
}
