.min-vh-75 {
  min-height: 75vh !important;
}

.banner {
  height: 100vh;
  min-height: 800px;
  position: relative;
  overflow: hidden;
  @include mw-1280 {
    height: 560px;
    min-height: auto;
  }
  @include mw-768 {
    height: 750px;
  }
  .bg-1 {
    position: absolute;
    right: 0px;
    top: 260px;
    border: 5px solid #f8973a;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    @include mw-1280 {
      width: 34px;
      height: 34px;
      top: 100px;
    }
  }
  .bg-2 {
    position: absolute;
    width: 123px;
    height: 123px;
    left: 300px;
    bottom: 130px;
    background: #ef5f21;
    opacity: 0.3;
    border-radius: 50%;
    @include mw-1280 {
      width: 68px;
      height: 68px;
      bottom: 100px;
    }
  }
  .bg-3 {
    position: absolute;
    width: 44px;
    height: 44px;
    left: -50px;
    top: 220px;
    background: #ef5f21;
    opacity: 0.3;
    border-radius: 50%;
    @include mw-1280 {
      width: 20px;
      height: 20px;
    }
  }
  &-info {
    padding-top: 200px;
    position: relative;
    z-index: 3;
    @include mw-1280 {
      padding-top: 100px;
    }
    h1 {
      line-height: 63px;
      letter-spacing: 0.0025em;
      font-size: 45px;
      font-weight: 300;
      @include mw-1280 {
        font-size: 30px;
        line-height: 34px;
      }
      @include mw-768 {
        font-size: 24px;
      }
      strong {
        font-weight: 700;
        @include mw-1280 {
          display: inline-block;
        }
        @include mw-768 {
          margin-top: 0px;
        }
      }
    }
  }
  &-list {
    display: none;
    @include mw-768 {
      display: block;
    }
    .slick-arrow {
      display: none !important;
    }
    .slick-dots {
      bottom: -25px;
    }
  }
  &-item {
    height: 460px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .container {
    position: relative;
    height: 100%;
    .business-1 {
      top: 45%;
      right: 50%;
      @include mw-1280 {
        width: 200px;
      }
    }
    .business-2 {
      top: 25%;
      right: 25%;
      @include mw-1280 {
        width: 190px;
      }
    }
    .business-3 {
      top: 10%;
      right: 6%;
      @include mw-1280 {
        width: 134px;
        top: 20%;
      }
    }
    .business-4 {
      top: 80%;
      right: 30%;
      @include mw-1280 {
        width: 220px;
      }
    }
    .business-5 {
      top: 50%;
      right: 0%;
      @include mw-1280 {
        width: 182px;
        top: 60%;
      }
    }
    .main-box-image {
      position: absolute;
      transition: all 0.8s;
      img {
        width: 100%;
        box-shadow: 0 20px 50px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
      }
      @include mw-768 {
        display: none;
      }
    }
  }
}
.main-ani {
  .business-1 {
    -webkit-animation: 45s business-1 infinite linear;
    animation: 45s business-1 infinite linear;
    @include mw-1280 {
      animation: none;
    }
  }
  .business-2 {
    -webkit-animation: 45s business-2 infinite linear;
    animation: 45s business-2 infinite linear;
    @include mw-1280 {
      animation: none;
    }
  }
  .business-3 {
    -webkit-animation: 45s business-3 infinite linear;
    animation: 45s business-3 infinite linear;
    @include mw-1280 {
      animation: none;
    }
  }
  .business-4 {
    -webkit-animation: 45s business-4 infinite linear;
    animation: 45s business-4 infinite linear;
    @include mw-1280 {
      animation: none;
    }
  }
  .business-5 {
    -webkit-animation: 45s business-5 infinite linear;
    animation: 45s business-5 infinite linear;
    @include mw-1280 {
      animation: none;
    }
  }
}
.about {
  background-color: #f5f8ff;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  @include mw-1280 {
    padding: 30px 0;
  }
  &-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include mw-991 {
      display: block;
    }
  }
  .section-title {
    @include mw-1280 {
      margin-top: 20px;
      margin-right: 20px;
    }
    @include mw-991 {
      text-align: center;
      span {
        margin: auto;
      }
    }
    @include mw-768 {
      margin-right: 0;
    }
  }
  &-list {
    display: flex;
    align-items: flex-start;
    @include mw-768 {
      display: block;
    }
  }
  &-item {
    list-style: none;
    padding: 0 50px;
    // padding-left: 0;
    min-width: 250px;
    @include mw-1280 {
      margin: 0;
      min-width: 130px;
    }
    @include mw-768 {
      margin: 20px;
    }
    #counter-box {
      font-size: 50px;
      font-weight: 600;
      display: flex;
      @include mw-1280 {
        font-size: 40px;
      }
      @include mw-768 {
        justify-content: center;
      }
    }
    li {
      &:last-child {
        color: #8e8e8e;
      }
      @include mw-768 {
        text-align: center;
      }
    }
  }
  &::before {
    content: '';
    position: absolute;
    width: 738px;
    height: 738px;
    left: 1270px;
    top: -554px;

    background: #a8c2ff;
    opacity: 0.2;
    filter: blur(100px);
    @include mw-768 {
      // display: none;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 540px;
    height: 540px;
    left: 72px;
    top: 118px;
    background: #bacfff;
    opacity: 0.15;
    filter: blur(120px);
    @include mw-768 {
      // display: none;
    }
  }
}
.influencers {
  overflow: hidden;
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 700px;
    padding: 50px 0;
    @include mw-1280 {
      min-height: 400px;
    }
    @include mw-768 {
      display: block;
    }
  }
  &--content {
    width: 50%;
    margin-right: -100px;
    @include mw-768 {
      width: 100%;
      margin-right: auto;
      margin-bottom: 50px;
    }
  }
  &--img {
    background-repeat: no-repeat;
    background-size: 94%;
    background-position: center;
    background-image: url('../../../../public/assets/box-studio/influencers/background.png');
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 60%;
    height: 700px;
    @include mw-1280 {
      height: 400px;
    }
    @include mw-768 {
      width: 100%;
      height: auto;
      transform: scale(1.2);
    }

    &--inner {
      position: relative;
      width: 300px;
      height: 300px;
      margin: 0 auto;

      @include mww-1920 {
        transform: scale(2.2);
      }
      @include mw-1920 {
        transform: scale(2);
      }
      @include mw-1800 {
        transform: scale(1.9);
      }
      @include mw-1500 {
        transform: scale(1.6);
      }
      @include mw-1320 {
        transform: scale(1.5);
      }
      @include mw-1280 {
        transform: scale(1.2);
      }
      @include mw-840 {
        transform: scale(1);
      }
      @include mw-768 {
        height: 550px;
        transform: scale(1.5);
      }
      @include mw-650 {
        height: 470px;
        transform: scale(1.2);
      }
      @include mw-500 {
        height: 358px;
      }
      @include mw-400 {
        height: 282px;
        transform: scale(0.9);
      }

      & img {
        position: absolute;
        cursor: pointer;
        border-radius: 50%;
        transform-origin: 50% 50%;

        &.influencer-0 {
          position: absolute;
          width: 156px;
          height: 156px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.influencer-1 {
          width: 113px;
          height: 113px;
          top: 50%;
          left: 100%;
          transform: rotate(53deg) translateX(-173px) rotate(307deg);
        }
        &.influencer-2 {
          width: 50px;
          height: 50px;
          top: 50%;
          left: 100%;
          transform: rotate(162deg) translateX(17px) rotate(207deg);
        }
        &.influencer-3 {
          width: 85px;
          height: 85px;
          top: 50%;
          left: 100%;
          transform: rotate(155deg) translateX(158px) rotate(202deg);
        }
        &.influencer-4 {
          width: 100px;
          height: 100px;
          top: 50%;
          left: 100%;
          transform: rotate(179deg) translateX(314px) rotate(180deg);
        }
        &.influencer-5 {
          width: 65px;
          height: 65px;
          top: 50%;
          left: 100%;
          transform: rotate(25deg) translateX(-310px) rotate(335deg);
        }

        &:hover {
          scale: 1.02;
          transition: all 0.5s ease 0s;
        }
      }
    }
  }
}
.social {
  background: linear-gradient(180deg, #000a24 61.39%, #00154a 107.28%);
  &-inner {
    background-image: url(../../images/bg/bg-social.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    padding: 50px 0;
  }
  .container {
    @include mw-1280 {
      max-width: 900px;
    }
  }
  .section-title {
    text-align: center;
    color: #fff;
    margin-bottom: 50px;
    span {
      margin: 20px auto;
    }
  }

  &-content {
    display: flex;
  }
  &-img {
    img {
      width: 100%;
      @include mw-768 {
        width: 100%;
        margin: 0;
      }
    }
  }
  .center {
    padding: 40px;
    @include mw-1280 {
      padding: 0;
    }
    @include mw-768 {
      // padding: 60px;
    }
    @include mw-650 {
      padding: 40px 70px;
    }
  }
}
.network {
  padding: 60px 0;
  .section-title {
    text-align: center;
    span {
      margin: 20px auto;
    }
  }
  .section-content {
    text-align: center;
    margin: 0px auto;
    max-width: 885px;
  }
  &-item {
    background-image: url(../../images/social/bg.svg);
    max-width: 560px;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    height: 71px;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;

    @include mw-1280 {
      background-image: url(../../images/social/bg-2.svg);
      max-width: 420px;
    }
    @include mw-768 {
      background-image: url(../../images/social/bg-3.svg);
      max-width: 360px;
      height: 75px;
    }
  }
  &-img {
    border-radius: 50%;
    border: solid 2px #fdcc9a;
    width: 72px;
    height: 72px;
    margin-left: -2px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-list {
    padding: 50px 0;
    .row {
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    width: calc(100% -80px);
    padding: 0 20px;
    // align-items: flex-end;
    align-items: center;
    @include mw-1280 {
      padding: 0 10px;
    }
    @include mw-991 {
    }
    span {
      &:first-child {
        font-size: 17px;
        text-transform: uppercase;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        @include mw-1280 {
          font-size: 16px;
          padding-right: 20px;
        }
        @include mw-768 {
          font-size: 14px;
        }
      }
      &:last-child {
        font-size: 22px;
        display: flex;
        align-items: baseline;
        line-height: 22px;
        white-space: nowrap;
        @include mw-768 {
          font-size: 20px;
        }
        small {
          font-size: 17px;
          font-weight: 300;
          // margin-bottom: -5px;
          margin-left: 5px;
          line-height: 20px;
        }
      }
    }
  }
}
.tiktok {
  position: relative;
  .bg-1 {
    position: absolute;
    right: 100px;
    top: -150px;
    opacity: 0.2;
    @include mw-768 {
      display: none;
    }
  }
  .bg-2 {
    position: absolute;
    bottom: 150px;
    opacity: 0.2;
    left: 10%;
    transform: rotate(30deg);
  }
  .bg-3 {
    position: absolute;
    width: 100px;
    height: 100px;
    right: 340px;
    top: -13px;
    background: radial-gradient(
      147.47% 147.47% at 50.51% 122.22%,
      #ff4d00 21.53%,
      #ef8421 87.67%
    );
    opacity: 0.8;
    border-radius: 50%;
    @include mw-768 {
      width: 60px;
      height: 60px;
      top: 50%;
      right: 30%;
    }
  }
  &-item {
    position: relative;
  }
  .play {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    z-index: 3;
    opacity: 0;
    width: 60px;
    height: 60px;
    background-color: transparent;
    box-shadow: none;
    border: none;
    &:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }

  .slick-slide {
  }
  /* the parent */
  .slick-list {
    margin: 0 -26px;
  }
  .tiktok-list {
    width: 600px;
    margin: 0 auto;
    @include mw-1280 {
      width: 400px;
    }
    @include mw-991 {
      width: 70%;
    }
    @include mw-768 {
      width: 70%;
    }
  }

  .slick-track {
    padding: 100px 0;
  }
  .slick-slide {
    position: relative;
    z-index: 1;

    img {
      width: 100%;
    }
  }
  .slick-center {
    z-index: 2;
    position: relative;
    img {
      transform: scale(1.2);
      @extend .tran;
    }
    .play {
      opacity: 1;
    }
  }
  .slick-arrow {
    position: absolute;
    z-index: 2;
    width: 50px;
    height: 50px;
    @include mw-768 {
      width: 30px;
      height: 30px;
    }
  }
  .slick-prev {
    left: 20px;
    @include mw-768 {
      left: 0;
    }
  }
  .slick-next {
    right: 20px;
    @include mw-768 {
      right: 0;
    }
  }
}
.level-1 {
  li {
    list-style: none;
    position: relative;
    font-weight: 300;
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 20px;
    &::after {
      content: url(../../images/bg/arrow.svg);
      width: 30px;
      height: 30px;
      position: absolute;
      left: -30px;
      top: 4px;
    }
  }
  .level-2 {
    margin-top: 10px;
    li {
      list-style: disc;
      &::marker {
        color: $main-color;
      }
      &::after {
        content: '';
      }
      ul {
        margin-top: 20px;
      }
    }
  }
  strong {
    font-weight: 600;
  }
}
.sport {
  padding: 50px 0;
  position: relative;
  padding-bottom: 0;
  &-bg {
    background: #f5f8ff;
    padding-bottom: 50px;
    padding-top: 300px;
    margin-top: -240px;
    @include mw-768 {
      margin-top: 0;
      padding-top: 30px;
      background-color: transparent;
    }
    .row {
      align-items: center;
    }
  }
  .col-md-4 {
    @include mw-1280 {
      width: 25%;
    }
    @include mw-768 {
      width: 100%;
    }
  }
  .col-md-8 {
    @include mw-1280 {
      width: 75%;
    }
    @include mw-768 {
      width: 100%;
    }
  }
  .col-md-5 {
    @include mw-1280 {
      width: 50%;
    }
    @include mw-768 {
      width: 100%;
    }
  }
  .col-md-7 {
    @include mw-1280 {
      width: 50%;
    }
    @include mw-768 {
      width: 100%;
    }
  }
  &-list {
    display: flex;
    margin: 0 -10px;
    margin-top: 25px;
    @include mw-650 {
      display: block;
      margin: 0;
    }
  }
  &-inner {
    background: #00000067;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
  }
  &-item {
    margin: 0 10px;
    width: 20%;
    height: 360px;
    background-size: cover;
    display: block;
    text-decoration: none;
    @include mw-1280 {
      height: 200px;
    }
    @include mw-650 {
      width: 100%;
      height: 400px;
      margin: 0;
      margin-bottom: 20px;
    }
    h3 {
      color: #fff;
      text-align: center;
      font-weight: 700;
      @include mw-1280 {
        font-size: 16px;
      }
      @include mw-650 {
        font-size: 30px;
        font-weight: 700;
      }
    }
  }
  &-title {
    font-weight: 600;
    font-size: 37px;
    line-height: 47px;
    letter-spacing: 0.0025em;
    max-width: 512px;
    @include mw-1280 {
      font-size: 30px;
    }
    @include mw-768 {
      text-align: center;
      font-size: 24px;
      max-width: 100%;
    }
  }
  .right {
    img {
      width: 100%;
    }
  }
}
.live {
  overflow: hidden;
  position: relative;
  .bg-1 {
    position: absolute;
    opacity: 0.2;
    bottom: 50px;
    width: 400px;
    transform: rotate(30deg);
    @include mw-768 {
      display: none;
    }
  }
  .bg-2 {
    position: absolute;
    opacity: 0.1;
    top: -50px;
    right: -100px;
    width: 600px;
    transform: rotate(-45deg);
    @include mw-768 {
      display: none;
    }
  }
  .row {
    align-items: center;
    min-height: 900px;
    @include mw-1280 {
      min-height: 600px;
    }
  }
  .col-md-7 {
    padding-right: 100px;
    padding-bottom: 200px;
    @include mw-1280 {
      padding-right: 40px;
    }
    @include mw-768 {
      padding-bottom: 0;
    }
  }
  &-img {
    position: relative;
    min-height: 900px;
    height: 100%;
    @include mw-1280 {
      min-height: 560px;
    }

    @include mw-768 {
      min-height: 300px;
    }
    img {
      position: absolute;
      left: -400px;
      bottom: 0;
      @include mw-1280 {
        height: 560px;
        left: -200px;
      }
      @include mw-991 {
        height: 450px;
      }
      @include mw-768 {
        position: initial;
        width: 100%;
        height: auto;
      }
    }
  }
}
.partner {
  margin-top: -100px;
  @include mw-768 {
    margin-top: 0;
  }
  .section-title {
    text-align: center;
    margin-bottom: 70px;
    span {
      margin: 20px auto;
    }
    @include mw-768 {
      margin-bottom: 40px;
    }
  }
  &-item {
    border: 1px solid #e0e0e0;
    padding: 15px 30px;
    margin-bottom: 30px;
    height: 100px;
    display: block;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      @extend .tran;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    @include mw-1280 {
      padding: 10px 20px;
    }
    @include mw-768 {
      padding: 10px;
      height: 50px;
      margin-bottom: 15px;
    }
  }
  .row {
    justify-content: center;
  }
  .col-md-2 {
    @include mw-1280 {
      width: 25%;
      max-width: 25%;
    }
    @include mw-768 {
      width: 33.33%;
      max-width: 33.33%;
    }
  }
}
.news {
  padding: 50px 0 100px;
  .section-title {
    text-align: center;
    margin-bottom: 70px;
    @include mw-768 {
      margin-bottom: 40px;
    }
    span {
      margin: 20px auto;
    }
    a {
      color: #282828;
      text-decoration: none;
      &:hover {
        color: #ef5f21;
      }
    }
  }
  &-item {
    text-decoration: none;
    margin-bottom: 40px;
    display: block;
    @extend .tran;
    h5 {
      color: #282828;
      font-weight: 600;
    }
    &:hover {
      text-decoration: none;
      h5 {
        color: $main-color;
      }
    }
  }
  &-img {
    height: 200px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .col-md-3 {
    @include mw-1280 {
      width: 50%;
      max-width: 50%;
    }
    @include mw-650 {
      width: 100%;
      max-width: 100%;
    }
  }
}
.modal-tiktok {
  .btn-close {
    @include mw-768 {
      top: 15px;
      right: 15px;
    }
    &:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
}

.show {
  .tiktok-embed {
    display: block;
  }
}

.cursor-no-drop {
  cursor: no-drop !important;
}
